import api from '@libs/axios'
import {
  setAccessToken,
  setUserData,
} from '@/libs/cookie'
import { updateAbility } from '@/libs/acl/ability'

export default {
  async login(payload) {
    const response = await api.post('/auth-admin/login', payload)
    return response;
  },

  async logout() {
    setUserData(null)
    setAccessToken(null)
    updateAbility()
  },

  async forgetPassword(payload) {
    const response = await api.post('/auth-admin/forget-password', payload)
    return response
  },

  async verifyCode(payload) {
    let response = await api.post('/auth-admin/verify-code', payload)
    return response
  },

  async setNewPassword(payload) {
    const response = await api.put('/auth-admin/new-password', payload)
    return response
  },
}
